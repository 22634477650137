<template>
  <div class="accountForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      @update="update"
      :submitBefore="submitBefore"
    >
      <el-form-item label="手机号" :rules="validateTel" prop="account">
        <v-input
          placeholder="请输入您的手机号"
          v-model="form.account"
          :width="250"
          :maxlength="11"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="请设置您的密码"
        :rules="validatePass"
        prop="password"
      >
        <v-input
          :width="250"
          v-model="form.password"
          type="password"
          placeholder="请设置您的密码"
          name="password"
          autocomplete="on"
          :maxlength="16"
        />
      </el-form-item>
      <el-form-item
        label="请再次输入密码"
        :rules="newPasswordRepeat"
        prop="secPassword"
      >
        <v-input
          :width="250"
          v-model="form.secPassword"
          type="password"
          placeholder="请再次输入密码"
          name="password"
          autocomplete="on"
          :maxlength="16"
        />
      </el-form-item>
      <el-form-item
        label="所属社区"
        :rules="[
          { required: true, message: '请选择所属社区', trigger: 'blur' },
        ]"
        prop="communityRelated"
      >
        <v-select
          :options="communityList"
          v-model="form.communityRelated"
          @change="changecommunityList"
        />
      </el-form-item>
      <el-form-item
        label="建设方式"
        :rules="[
          { required: true, message: '请选择建设方式', trigger: 'blur' },
        ]"
        prop="buildType"
      >
        <v-select :options="buildTypeList" v-model="form.buildType" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { tenantListUrl, addClientUrl } from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import { mobileRegular, passRegular } from "@/utils/regular";
import { buildTypeList, buildTypeListMap } from "./map";
import encrypt from "@/utils/encrypt";
export default {
  name: "accountForm",
  data() {
    let validatePassRule = (rule, value, callback) => {
      if (!passRegular.test(value)) {
        callback(new Error("请填写最大长度16位字符的密码"));
      } else {
        callback();
      }
    };
    let validateTelRule = (rule, value, callback) => {
      if (!mobileRegular.test(value)) {
        callback(new Error("请填写正确的手机号"));
      } else {
        callback();
      }
    };
    let validateNewPasswordRepeat = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次密码必须一致"));
      } else {
        callback();
      }
    };
    return {
      buildTypeList,
      validateTel: [
        {
          required: true,
          message: "请输入您的手机号",
          trigger: "blur",
        },
        {
          validator: validateTelRule,
          trigger: "blur",
        },
      ],
      newPasswordRepeat: [
        {
          required: true,
          message: "请输入确认密码",
          trigger: "blur",
        },
        {
          validator: validateNewPasswordRepeat,
          trigger: "blur",
        },
      ],
      validatePass: [
        {
          required: true,
          message: "请输入您的密码",
          trigger: "blur",
        },
        {
          validator: validatePassRule,
          trigger: "blur",
        },
      ],
      submitConfig: {
        queryUrl: "",
        submitUrl: addClientUrl,
      },
      form: {
        buildType: "",
        password: "",
        secPassword: "",
        account: "",
        communityRelated: "",
        companyName: "",
      },
      communityList: [],
    };
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
    }

    this.$setBreadList(id ? "编辑" : "新增");
    this.getTenantListUrl();
  },
  computed: {},
  methods: {
    changecommunityList() {
      this.communityList.forEach((ele) => {
        if (ele.value == this.form.communityRelated) {
          this.form.companyName = ele.label;
        }
      });
    },
    async getTenantListUrl() {
      this.kindsList = [];
      let res = await this.$axios.get(`${tenantListUrl}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            item.label = item.tenantName;
            item.value = item.tenantId;
            this.communityList.push(item);
          });
        }
      }
    },
    submitBefore(data) {
      return {
        buildType: this.form.buildType,
        password: encrypt.sha1(encrypt.md5.hexMD5(this.form.password)),
        account: this.form.account,
        companyName: this.form.companyName,
        communityRelated: this.form.communityRelated,
      };
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.accountForm {
  box-sizing: border-box;
  height: 100%;
  padding: 0 40px;
}
</style>
